
import React from 'react';
import './contact.scss';
import { FaLocationArrow } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { AiOutlinePhone } from 'react-icons/ai';
import { Section } from 'react-scroll-section';


export const Contact = () => {

    return (
        <Section id="contact">
            <section className="contact-container">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-lg-6 col-md-10">
                            <div className="section-title text-center pb-10">
                                <h3 className="title">Kontakt</h3>
                                <p className="text">
                                    Skontaktuj sie z nami juz teraz!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center" >
                        <div className="row w-100">
                            <div className="col-lg-12">
                                <div className="contact-map">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10070.780536918002!2d20.680698645216275!3d50.873843513048236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4718271206453583%3A0x2e8cc8dbc60403e5!2sDomaszowice!5e0!3m2!1spl!2spl!4v1633366608628!5m2!1spl!2spl"
                                        frameBorder="0"
                                        style={{ border: 0 }}
                                        allowFullScreen={true}
                                        aria-hidden="false"
                                        tabIndex={0}
                                        title="map"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="col-lg-4 col-md-6 mt-3">
                            <div className="single-info d-flex align-items-center">
                                <div className="single-info-logo" style={{ background: '#121212' }}>
                                    <FaLocationArrow />
                                </div>
                                <div className="single-info-text pl-3">
                                    Kielce ul. Gabrieli Zapolskiej 5
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                            <div className="single-info d-flex align-items-center">
                                <div className="single-info-logo" style={{ background: '#fc3832' }}>
                                    <HiOutlineMail />
                                </div>
                                <div className="single-info-text pl-3">
                                    zpinwestycje@gmail.com
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                            <div className="single-info d-flex align-items-center">
                                <div className="single-info-logo" style={{ background: '#e5ae0b' }}>
                                    <AiOutlinePhone />
                                </div>
                                <div className="single-info-text pl-3">
                                    501-454-299<br />
                                    660-486-034
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Section>
    );
};

export default Contact;