import React from 'react';
import '../../App.css';
import { Button } from '../Button/Button';
import './HeroSection.scss';
import vide from '../../img/video2.mp4'
import { Section, useScrollSection } from 'react-scroll-section';

function HeroSection() {
  const apartamentsMapSection = useScrollSection('apartaments-map');
  return (
    <Section id="hero">
      <div className='hero-container' data-aos="fade-up">
        <video src={vide} autoPlay loop muted />
        <h1>DOMY <span style={{ color: '#e5ae0b' }}>ZAPROJEKTOWANE</span> Z PASJĄ</h1>
        <p>Poczuj czar miejsca, do którego chce się wracać. Miejsca, które pozwala odetchnąć pełną piersią i oderwać się od zgiełku centrum miasta. Poznaj prawdziwą alternatywę dla miejskiego apartamentu – własny dom i zieleń prywatnego ogródka.</p>
        <div className='hero-btns'>
          <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            onClick={apartamentsMapSection.onClick}
          >
            Wybierz mieszkanie
          </Button>
          <Button
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--large'
          >
            <a href="https://www.youtube.com/watch?v=DEZjVqRi0Ng&ab_channel=MichalSwiadek"> Zobacz Film <i className='far fa-play-circle' /></a>
          </Button>
        </div>
      </div>
    </Section>
  );
}

export default HeroSection;