
import React, { useEffect, useState } from 'react';
import './apartaments-gallery.scss';
import { Button, Modal } from 'react-bootstrap';
import zzew1 from '../../img/Z_ZEWNATRZ/1.PNG';
import zzew2 from '../../img/Z_ZEWNATRZ/2.PNG';
import zzew3 from '../../img/Z_ZEWNATRZ/3.PNG';
import zzew4 from '../../img/Z_ZEWNATRZ/4.PNG';
import zzew5 from '../../img/Z_ZEWNATRZ/5.PNG';
import zzew6 from '../../img/Z_ZEWNATRZ/6.PNG';
import zzew7 from '../../img/Z_ZEWNATRZ/7.PNG';
import zzew8 from '../../img/Z_ZEWNATRZ/8.PNG';
import zzew9 from '../../img/Z_ZEWNATRZ/9.PNG';
import zzew10 from '../../img/Z_ZEWNATRZ/10.PNG';
import zzew11 from '../../img/Z_ZEWNATRZ/11.PNG';

import wew1 from '../../img/WNETRZE/1.PNG'
import wew2 from '../../img/WNETRZE/2.PNG'
import wew3 from '../../img/WNETRZE/3.PNG'
import wew4 from '../../img/WNETRZE/4.PNG'
import wew5 from '../../img/WNETRZE/5.PNG'
import wew6 from '../../img/WNETRZE/6.PNG'
import wew7 from '../../img/WNETRZE/7.PNG'
import wew8 from '../../img/WNETRZE/8.PNG'
import wew9 from '../../img/WNETRZE/9.PNG'

import odgor1 from '../../img/OD_GORY/1.PNG';
import odgor2 from '../../img/OD_GORY/2.PNG';
import odgor3 from '../../img/OD_GORY/3.PNG';
import { Section } from 'react-scroll-section';


enum IMG_CATEGORIES {
    ALL = 'Wszystkie',
    MIESZKANIE = 'Mieszkanie',
    PLANY = 'Plany',
    ZZEWNATRZ = 'Front',
    WNETRZE = 'Wnętrze',
    ODGORY = 'Rzut z góry'
}

interface Gallery {
    src: string;
    category: IMG_CATEGORIES;
}

export const ApartamentsGallery = () => {
    const categories = [IMG_CATEGORIES.ALL, IMG_CATEGORIES.ZZEWNATRZ, IMG_CATEGORIES.WNETRZE, IMG_CATEGORIES.ODGORY];
    const gallery: Gallery[] = [
        { src: zzew1, category: IMG_CATEGORIES.ZZEWNATRZ },
        { src: zzew2, category: IMG_CATEGORIES.ZZEWNATRZ },
        { src: zzew3, category: IMG_CATEGORIES.ZZEWNATRZ },
        { src: zzew4, category: IMG_CATEGORIES.ZZEWNATRZ },
        { src: zzew5, category: IMG_CATEGORIES.ZZEWNATRZ },
        { src: zzew6, category: IMG_CATEGORIES.ZZEWNATRZ },
        { src: zzew7, category: IMG_CATEGORIES.ZZEWNATRZ },
        { src: zzew8, category: IMG_CATEGORIES.ZZEWNATRZ },
        { src: zzew9, category: IMG_CATEGORIES.ZZEWNATRZ },
        { src: zzew10, category: IMG_CATEGORIES.ZZEWNATRZ },
        { src: zzew11, category: IMG_CATEGORIES.ZZEWNATRZ },

        { src: wew1, category: IMG_CATEGORIES.WNETRZE },
        { src: wew2, category: IMG_CATEGORIES.WNETRZE },
        { src: wew3, category: IMG_CATEGORIES.WNETRZE },
        { src: wew4, category: IMG_CATEGORIES.WNETRZE },
        { src: wew5, category: IMG_CATEGORIES.WNETRZE },
        { src: wew6, category: IMG_CATEGORIES.WNETRZE },
        { src: wew7, category: IMG_CATEGORIES.WNETRZE },
        { src: wew8, category: IMG_CATEGORIES.WNETRZE },
        { src: wew9, category: IMG_CATEGORIES.WNETRZE },

        { src: odgor1, category: IMG_CATEGORIES.ODGORY },
        { src: odgor2, category: IMG_CATEGORIES.ODGORY },
        { src: odgor3, category: IMG_CATEGORIES.ODGORY },
    ]

    const [filteredGallery, setFilteredGallery] = useState(gallery);
    const [selectedCategory, setSelectedCategory] = useState(IMG_CATEGORIES.ALL);
    const [show, setShow] = useState(false);
    const [selectedImg, setSelectedImg] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (url: string) => {
        setShow(true);
        setSelectedImg(url)
    };

    useEffect(() => {
        if (selectedCategory === IMG_CATEGORIES.ALL) {
            setFilteredGallery(gallery);
        } else {
            const filtered = gallery.filter(el => el.category === selectedCategory)
            setFilteredGallery(filtered);
        }
    }
        , [selectedCategory])

    const modal = () => (
        <>
            <Modal show={show} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <img src={selectedImg} className="gallery-img" style={{ width: '100%', height: '100%' }} alt="mieszkania" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

    return (
        <Section id="apartaments-gallery">
            {modal()}
            <div className="apartaments-gallery-container" data-aos="fade-up">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-lg-6 col-md-10">
                            <div className="section-title text-center pb-10">
                                <h3 className="title">Galeria</h3>
                                <p className="text"> Zachęcamy do zapoznania sie z galerią naszej inwestycji. </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center" >
                        <div className="col-lg-3 col-md-3">
                            <div className="gallery-menu text-center mt-50">
                                <ul>
                                    {categories.map(category => (
                                        <li key={category} onClick={() => setSelectedCategory(category)} className={(selectedCategory === category ? 'active' : '')}>{category}</li>
                                    ))}

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9">
                            <div className="row no-gutters grid">
                                {filteredGallery.map((img, idx) => (
                                    <div className="col-lg-4 col-sm-6 p-1" key={`${img.src}-${idx}`}>
                                        <img src={img.src} className="gallery-img" onClick={() => handleShow(img.src)} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default ApartamentsGallery;