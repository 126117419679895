import React from 'react';
import './Footer.scss';
import { Button } from '../Button/Button';
import { Link } from 'react-router-dom';
import {AiFillFacebook, AiFillTwitterCircle, AiFillInstagram} from 'react-icons/ai';

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="social text-center mt-60 d-flex justify-content-center">
              <div className="social-media-icon">
                <AiFillFacebook />
              </div>
              <div className="social-media-icon">
                <AiFillTwitterCircle/>
              </div>
              <div className="social-media-icon">
                <AiFillInstagram />
              </div>
            </div>
            <div className="footer-info text-center mt-5 d-flex justify-content-center">
                501-454-299 <br/>
                660-486-034<br/>
                zpinwestycje@gmail.com
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;