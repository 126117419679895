
import React from 'react';
import './apartaments-info.scss';
import location from '../../img/location.svg'
import home from '../../img/home.svg'
import relax from '../../img/relax.svg'
import {
    Section,
} from 'react-scroll-section';

export const ApartamentsInfo = () => {


    return (
        <Section id="apartaments-info">
            <section className="apartaments-info-container" data-aos="flip-left">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-lg-6 col-md-10">
                            <div className="section-title text-center pb-10">
                                <h3 className="title">Mieszkania</h3>
                                <p className="text">Nasze mieszkania wyrożniają sie:</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-4 col-md-12 col-sm-12 mt-1">
                            <div className="single-box mt-40">
                                <div className="single-box-icon"><img src={location} alt="" style={{ width: '115px', height: '95px' }} /></div>
                                <div className="single-box-title-icon text-center">
                                    <h4 className="single-box-title pt-2">Idealna Lokalizacja</h4>
                                </div>
                                <div className="single-box-content">
                                    <p>Domki w centrum cichej i spokojnej okolicy Woli Kopcowej. Kilka minut od centrum miasta Kielce.a1
                                        Blisko centrum handlowego Echo oraz Korona.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 mt-1 text-center">
                            <div className="single-box mt-40">
                                <div className="single-box-icon"><img src={home} alt="" style={{ width: '115px', height: '95px' }} /></div>
                                <div className="single-box-title-icon text-center">
                                    <h4 className="single-box-title pt-2">Inspirujaca Architektura</h4>
                                </div>
                                <div className="single-box-content">
                                    <p>Nowoczesna architektura budynku i wnetrz. Projektowane przez zaufanych architektów. Najwyższa jakość materiałów</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 mt-1 text-center">
                            <div className="single-box mt-40">
                                <div className="single-box-icon"><img src={relax} alt="" style={{ width: '115px', height: '95px' }} /></div>
                                <div className="single-box-title-icon text-center">
                                    <h4 className="single-box-title pt-2">Metraże</h4>
                                </div>
                                <div className="single-box-content">
                                    <p>Mieszkania z tarasami. <br /> Przestronne i funkcjonalne wnetrza.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Section>
    );
};

export default ApartamentsInfo;