import React, { useState, useEffect } from 'react';
import './Navbar.scss';
import logo from '../../img/logobiale.jpg';
import { useScrollSection } from 'react-scroll-section';

function Navbar() {
  const heroSection = useScrollSection('hero');
  const apartamentsInfoSection = useScrollSection('apartaments-info');
  const apartamentsGellerySection = useScrollSection('apartaments-gallery');
  const contactSection = useScrollSection('contact');
  const realizationstSection = useScrollSection('realizations');

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };



  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar-custom'>
        <div className='navbar-container'>
          <span className='navbar-logo' onClick={() => {
            closeMobileMenu(); heroSection.onClick();
          }}>
            <img src={logo} alt="" style={{ height: '80px' }} />
          </span>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <span className='nav-links' onClick={() => {
                closeMobileMenu(); heroSection.onClick();
              }}>
                Home
              </span>
            </li>
            <li className='nav-item'>
              <span
                className='nav-links'
                onClick={() => {
                  closeMobileMenu(); apartamentsInfoSection.onClick();
                }}>
                Inwestycja
              </span>
            </li>
            <li className='nav-item'>
              <span
                className='nav-links'
                onClick={() => {
                  closeMobileMenu(); apartamentsGellerySection.onClick();
                }}>
                Galeria
              </span>
            </li>
            <li className='nav-item'>
              <span

                className='nav-links'
                onClick={() => {
                  closeMobileMenu(); realizationstSection.onClick();
                }}>
                Realizacje
              </span>
            </li>
            <li className='nav-item'>
              <span

                className='nav-links'
                onClick={() => {
                  closeMobileMenu(); contactSection.onClick();
                }}>
                Kontakt
              </span>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;