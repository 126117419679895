
import React from 'react';
import { Section } from 'react-scroll-section';
import './apartaments-table.scss';

enum Lokalizacja {
  PIETRO = 'Piętro',
  PARTER = 'Parter',
}

enum Status {
  WOLNE = 'Wolne',
  ZAREZERWOWANE = 'Zarezerwowane',
  SPRZEDANE = 'Sprzedane'
}


export const ApartamentsTable = () => {
  const flats = [
    { num: 1, lokalizacja: Lokalizacja.PARTER, powierzchnia: '75,91m²', dzialka: '95 m²', status: Status.WOLNE },
    { num: 2, lokalizacja: Lokalizacja.PIETRO, powierzchnia: '85,72m²', dzialka: '80 m²', status: Status.WOLNE },
    { num: 3, lokalizacja: Lokalizacja.PARTER, powierzchnia: '75,91m²', dzialka: '70 m²', status: Status.WOLNE },
    { num: 4, lokalizacja: Lokalizacja.PIETRO, powierzchnia: '85,72m²', dzialka: '30 m²', status: Status.WOLNE },
    { num: 5, lokalizacja: Lokalizacja.PARTER, powierzchnia: '75,91m²', dzialka: '70 m²', status: Status.WOLNE },
    { num: 6, lokalizacja: Lokalizacja.PIETRO, powierzchnia: '85,72m²', dzialka: '30 m²', status: Status.WOLNE },
    { num: 7, lokalizacja: Lokalizacja.PARTER, powierzchnia: '75,91m²', dzialka: '95 m²', status: Status.WOLNE },
    { num: 8, lokalizacja: Lokalizacja.PIETRO, powierzchnia: '85,72m²', dzialka: '60 m²', status: Status.WOLNE },
    { num: 9, lokalizacja: Lokalizacja.PARTER, powierzchnia: '75,91m²', dzialka: '95 m²', status: Status.ZAREZERWOWANE },
    { num: 10, lokalizacja: Lokalizacja.PIETRO, powierzchnia: '85,72m²', dzialka: '60 m²', status: Status.WOLNE },
    { num: 11, lokalizacja: Lokalizacja.PARTER, powierzchnia: '75,91m²', dzialka: '70 m²', status: Status.WOLNE },
    { num: 12, lokalizacja: Lokalizacja.PIETRO, powierzchnia: '85,72m²', dzialka: '30 m²', status: Status.WOLNE },
    { num: 13, lokalizacja: Lokalizacja.PARTER, powierzchnia: '75,91m²', dzialka: '70 m²', status: Status.WOLNE },
    { num: 14, lokalizacja: Lokalizacja.PIETRO, powierzchnia: '85,72m²', dzialka: '30 m²', status: Status.WOLNE },
    { num: 15, lokalizacja: Lokalizacja.PARTER, powierzchnia: '75,91m²', dzialka: '105 m²', status: Status.WOLNE },
    { num: 16, lokalizacja: Lokalizacja.PIETRO, powierzchnia: '85,72m²', dzialka: '200 m²', status: Status.WOLNE },

  ];
  return (
    <Section id="apartamets-table" data-aos="fade-up">
      <section className="apartaments-table-container">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-lg-6 col-md-10">
              <div className="section-title text-center pb-10">
                <h3 className="title">Mieszkania</h3>
                <p className="text">Sprawdz mieszkanie, ktore Cie interesuje</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>

                    <th className="th-sm">Numer</th>
                    <th className="th-sm">Lokalizacja</th>
                    <th className="th-sm">Powierzchnia</th>
                    <th className="th-sm">Działka</th>
                   
                  </tr>
                </thead>
                <tbody>
                  {flats.map((flat) => (
                    <tr>
                      <td>{flat.num}</td>
                      <td>{flat.lokalizacja}</td>
                      <td>{flat.powierzchnia}</td>
                      <td>{flat.dzialka}</td>
                    
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </Section>
  );
};

export default ApartamentsTable;