
import React from 'react';
import './realizations.scss';
import {
    Section,
} from 'react-scroll-section';

export const Realizations = () => {


    return (
        <Section id="realizations">
            <section className="realizations-container">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-lg-6 col-md-10">
                            <div className="section-title text-center pb-10">
                                <h3 className="title">Nasze Realizacje</h3>
                                <p className="text">Sprawdz nasze poprzednie realizacje!</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <iframe width="720" height="415" src="https://www.youtube.com/embed/c6jSXxlQqgo" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                    </div>
                </div>
            </section>
        </Section>
    );
};

export default Realizations;