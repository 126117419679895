import React, { useEffect } from 'react';
import '../App.css'
import HeroSection from '../components/Hero/HeroSection';
import Footer from '../components/Footer/Footer';
import ApartamentsMap from '../components/Apartaments-map/apartaments-map';
import ApartamentsTable from '../components/Apartaments-table/apartaments-table';
import ApartamentsInfo from '../components/Apartaments-info/apartaments-info';
import ApartamentsGallery from '../components/Apartaments-gallery/apartaments-gallery';
import Contact from '../components/Contact/contact';
import Navbar from '../components/Navbar/Navbar';
import 'aos/dist/aos.css';
import Aos from 'aos';
import { Realizations } from '../components/Realizations/realizations';

function Home() {
  useEffect(() => {
    Aos.init({ duration: 1500 })
  }, [])
  return (
    <>
      <Navbar />
      <HeroSection />
      <ApartamentsMap></ApartamentsMap>
      <ApartamentsTable></ApartamentsTable>
      <ApartamentsInfo></ApartamentsInfo>
      <Realizations></Realizations>
      <ApartamentsGallery></ApartamentsGallery>
      <Contact></Contact>
      <Footer />
    </>
  );
}

export default Home;