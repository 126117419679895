import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {
  ScrollingProvider,
} from 'react-scroll-section';
import Home from './pages/Home';

function App() {
  return (
    <ScrollingProvider>
      <Home></Home>
    </ScrollingProvider>
  );
}

export default App;