import React from 'react';
import './Button.scss';
import { Link } from 'react-router-dom';

const STYLES = ['btn--primary', 'btn--outline', 'btn--test'];
const SIZES = ['btn--medium', 'btn--large'];


interface ButtonProps{
    children?: any;
    type?:any;
    onClick?: any;
    buttonStyle?: any;
    buttonSize?: any;
    className?:string;
}

export const Button: React.FC<ButtonProps> = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize,
    className
  }: ButtonProps) => {

  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    <span  className='btn-mobile'>
      <button
        className={`btn ${checkButtonStyle} ${checkButtonSize}`}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
    </span>
  );
};