
import React from 'react';
import './apartaments-map.scss';
import ImageMapper from 'react-image-mapper';
import home from '../../img/panorama.png'
import { Section } from 'react-scroll-section';
import Bottom from '../../pdf/typ1.pdf';
import Top from '../../pdf/typ2.pdf';
import zdj from '../../img/dzialki.png'




export const ApartamentsMap = () => {

  const openPdf = (pdf: any) => {
    console.log(pdf)
    window.open(pdf)

  }

  const URL = `${home}`
  const MAP = {
    name: "my-map",
    areas: [
      { name: "Mieszkanie 1", flat: Bottom, shape: "poly", coords: [279,376,279,418,141,417,141,377], fillColor: "rgba(88, 237, 123, 0.5)", strokeColor: 'green' },
      { name: "Mieszkanie 2", flat: Top, shape: "poly", coords: [280,376,279,251,277,251,277,244,255,244,256,257,232,256,152,314,140,313,140,327,141,378], fillColor: "rgba(88, 237, 123, 0.5)", strokeColor: 'green' },
      { name: "Mieszkanie 3", flat: Bottom, shape: "poly", coords: [280,376,406,376,406,417,279,419], fillColor: "rgba(88, 237, 123, 0.5)", strokeColor: 'green' },
      { name: "Mieszkanie 4", flat: Top, shape: "poly", coords: [406,376,406,329,417,330,418,313,408,313,325,256,304,256,304,245,282,244,282,252,279,251,280,376], fillColor: "rgba(88, 237, 123, 0.5)", strokeColor: 'green' },
      { name: "Mieszkanie 5", flat: Bottom, shape: "poly", coords: [632,376,632,418,507,417,505,376], fillColor: "rgba(88, 237, 123, 0.5)", strokeColor: 'green' },
      { name: "Mieszkanie 6", flat: Top, shape: "poly", coords: [632,376,632,246,629,246,629,240,609,239,608,256,585,256,504,313,494,313,494,332,504,330,505,376], fillColor: "rgba(88, 237, 123, 0.5)", strokeColor: 'green' },
      { name: "Mieszkanie 7", flat: Bottom, shape: "poly", coords: [632,412,631,371,758,370,758,413], fillColor: "rgba(88, 237, 123, 0.5)", strokeColor: 'green' },
      { name: "Mieszkanie 8", flat: Top, shape: "poly", coords: [758,369,759,325,770,325,771,308,762,309,680,251,657,251,657,240,637,239,636,246,632,246,632,372], fillColor: "rgba(88, 237, 123, 0.5)", strokeColor: 'green' },
      { name: "Mieszkanie 9", flat: Bottom, shape: "poly", coords: [1317,366,1318,409,1190,410,1190,367], fillColor: "rgba(88, 237, 123, 0.5)", strokeColor: 'green' },
      { name: "Mieszkanie 10", flat: Top, shape: "poly", coords: [1317,366,1316,236,1294,235,1293,247,1269,248,1190,304,1178,305,1178,323,1190,322,1191,368], fillColor: "rgba(88, 237, 123, 0.5)", strokeColor: 'green' },
      { name: "Mieszkanie 11", flat: Bottom, shape: "poly", coords: [1318,403,1443,403,1443,361,1318,361], fillColor: "rgba(88, 237, 123, 0.5)", strokeColor: 'green' },
      { name: "Mieszkanie 12", flat: Top, shape: "poly", coords: [1443,360,1444,316,1456,315,1457,299,1448,299,1365,242,1341,242,1341,230,1319,231,1319,237,1316,237,1317,361], fillColor: "rgba(88, 237, 123, 0.5)", strokeColor: 'green' },
      { name: "Mieszkanie 13", flat: Bottom, shape: "poly", coords: [1670,362,1669,404,1543,403,1543,362], fillColor: "rgba(88, 237, 123, 0.5)", strokeColor: 'green' },
      { name: "Mieszkanie 14", flat: Top, shape: "poly", coords: [1669,362,1669,236,1667,236,1667,230,1645,230,1645,242,1622,242,1540,300,1532,300,1531,315,1544,315,1543,362], fillColor: "rgba(88, 237, 123, 0.5)", strokeColor: 'green' },
      { name: "Mieszkanie 15", flat: Bottom, shape: "poly", coords: [1669,362,1795,363,1795,404,1669,404], fillColor: "rgba(88, 237, 123, 0.5)", strokeColor: 'green' },
      { name: "Mieszkanie 16", flat: Top, shape: "poly", coords: [1796,363,1795,315,1808,315,1808,299,1800,299,1718,241,1694,242,1694,230,1672,230,1673,237,1668,237,1670,363], fillColor: "rgba(88, 237, 123, 0.5)", strokeColor: 'green' },


    ]
  }
  return (
    <Section id="apartaments-map">
      <section className="apartaments-map-container" data-aos="fade-up">
        <div className="map-box">
          <div className="row justify-content-center mb-5">
            <div className="col-lg-6 col-md-10">
              <div className="section-title text-center pb-10">
                <h3 className="title">Nasze mieszkania</h3>
                <p className="text">Najedź myszką na wybrane piętro dowolnego budynku i kliknij, by poznać plan rozkładu apartamentów.</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center" style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
            <ImageMapper src={URL} map={MAP} width={1944} height={455} onClick={(e: any) => openPdf(e.flat)} />
          </div>
          <div className="row justify-content-center mb-5 mt-5">
            <div className="col-lg-6 col-md-10">
              <div className="section-title text-center pb-10">
                <h3 className="title">Podział działek</h3>
              </div>
            </div>
          </div>
          <div className="row justify-content-center" style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
            <img src={zdj} alt="działki" />
          </div>
        </div>
      </section>
    </Section>
  );
};

export default ApartamentsMap;